ion-popover.popoverOrderFilters {
  --min-width: 275px;
}

.custom-item {
  --min-height: 25px;
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-top: 0px;
  --inner-padding-bottom: 0px;
  --border-width: 0px;
  --background: transparent;
  cursor: pointer;
}